import React from 'react';

import useSetLocale from '../hooks/useSetLocale';
import t, { _t } from '../utils/translate';
import Layout from '../components/Layout/Layout';
import SubpageHeader from '../components/SubpageHeader/SubpageHeader';
import SubpageBlocks from '../components/SubpageBlocks/SubpageBlocks';
import SubpageLoader from '../components/SubpageLoader/SubpageLoader';
import SEO from '../components/seo';

const VisitSubpage = ({ pageContext, location }) => {
  useSetLocale();
  const {
    intro,
    title,
    headline,
    body,
    learningResources,
    showLearningResources,
    shortTitle,
    imageOrVideo,
  } = pageContext.data;

  return (
    <Layout location={location}>
      <SEO
        title={`${_t(headline)} | SeeSalmon`}
        // description={seo && seo.description ? seo.description : null}
        // image={seo && seo.image ? seo.image.asset.fixed.src : null}
      />
      <SubpageHeader
        title={title}
        headline={headline}
        intro={intro}
        media={imageOrVideo}
        location={location}
        shortTitle={shortTitle}
      />
      <div className="page-padding">
        {body && <SubpageBlocks location={location} contents={body} />}
      </div>
      {showLearningResources && (
        <SubpageLoader
          dark={false}
          headline={t('learningHeadline')}
          loadFrom="learning"
          edges={learningResources.edges.map(edge => edge.node)}
        />
      )}
    </Layout>
  );
};

export default VisitSubpage;
